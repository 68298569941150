import React, { useState } from "react"

import './filmstrip.css'

const FilmStrip = (props) => {
    const [elementWidth, setElementWidth] = useState(0);
    const [imageHidden, setImageHidden] = useState(true);
    const [imageLeft, setImageLeft] = useState(0);
    const [sliderHidden, setSliderHidden] = useState(true);
    const [sliderPosition, setSliderPosition] = useState(0);
    let element;

    let elementStyle = {
        width: elementWidth
    }

    let imageStyle = {
        position: 'absolute',
        cursor: 'pointer',
        left: imageLeft+'px',
        display: imageHidden ? "none" : "block"
    };

    let sliderStyle = {
        width: "2px",
        height: "100%",
        background: "#ddd",
        position: "absolute",
        top: "0",
        opacity: "0.6",
        cursor: "pointer",
        zIndex: 1,
        left: sliderPosition+'px',
        display: sliderHidden ? "none" : "block"
    };


    let defaultSliderPosition = () => {
        setImageLeft(-elementWidth * Math.floor(props.frameCount / 4))
    };

    let onImageLoaded = (e) => {
        setElementWidth(e.target.width / props.frameCount);
        setImageHidden(false);
        defaultSliderPosition();
    };

    let scroller = clientX => {
        let left = clientX - element.getBoundingClientRect().left;
        setSliderPosition(left -1);
        setSliderHidden(false);
        setImageLeft(-Math.floor((left / elementWidth) * props.frameCount) * elementWidth);
    };

    let onMouseMove = ({nativeEvent}) => {
        scroller(nativeEvent.clientX);
    };

    let onTouchMove = ({nativeEvent}) => {
        scroller(nativeEvent.touches[0].clientX);
    };

    let onMouseOut = () => {
        setSliderHidden(true);
    };

    return <a ref={n => element = n} style={elementStyle} href={props.source} className={"video-preview"} onMouseMove={onMouseMove} onMouseOut={onMouseOut} onTouchMove={onTouchMove}>
        <img style={imageStyle} src={props.source} onLoad={onImageLoaded}/>
        <div style={sliderStyle}></div>
    </a>
}

export default FilmStrip