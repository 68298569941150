import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FilmStrip from "../components/filmstrip"


const FilmStripTest = () => (
  <Layout>
    <SEO title="Movie Filmstrip Test" />
    <h1>Moviestrip Test</h1>
    <p>Github: <a href={"https://github.com/ck99/movie-filmstrip"}>movie-filmstrip</a></p>
      <FilmStrip frameCount={100} source={"https://i.imgur.com/wUgNsgV.jpg"}></FilmStrip>
      <FilmStrip frameCount={50} source={"https://i.imgur.com/o3tW4Ek.jpg"}></FilmStrip>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default FilmStripTest
